import React from "react"

import cm from "./Footer.module.scss"
import { useIntl } from "react-intl"
import { withPrefix } from "gatsby";
import { useLocation } from '@reach/router';

const Footer: React.FC = ({ }) => {
  const { formatMessage: fm } = useIntl();
  const location = useLocation();
  const shareHref = location.href ?? "https://www.hungarocontrol.hu/"

  return (
    <footer>
      <div className={`${cm.logoFooter}`}>
          <div className="row">
              <div className="large-5 columns hidden-large-down">
                <a href="https://www.hungarocontrol.hu/" target="_blank">
                  <img src={withPrefix("/svg/hungaro-control_logo-white.svg")} alt="Hungaro Control" className={`${cm.logoHc}`} />
                </a>
              </div>
              <div className={`large-7 columns text-right ${cm.mobileOrder}`}>
                <span className={`${cm.powered}`}>
                  {fm({ id: "footer.keszitette" })}
                  <a href="http://bigfish.hu" target="_blank">
                    <img src={withPrefix("/svg/bigfish_logo.svg")} alt="BIG FISH" title="BIG FISH" className={`${cm.logoBigfish}`} />
                  </a>
                </span>
                <div className={`${cm.copy} hidden-large-up`}>© 2020 - HungaroControl</div>
                <span className={`${cm.socials}`}>
                  <a href="https://www.linkedin.com/company/hungarocontrol" target="_blank">
                    <img src={withPrefix("/svg/linkedin_icon_white.svg")} alt="linkedin" title="linkedin" className={`${cm.logoSocial}`} />
                  </a>
                  <a href="http://www.youtube.com/user/hungarocontrol" target="_blank">
                    <img src={withPrefix("/svg/youtube_icon_white.svg")} alt="youtube" title="youtube" className={`${cm.logoSocial}`} />
                  </a>
                  <a href="https://www.facebook.com/HungaroControlZrt/" target="_blank">
                    <img src={withPrefix("/svg/facebook_icon_white.svg")} alt="facebook" title="facebook" className={`${cm.logoSocial}`} />
                  </a>
                  <a href="https://www.instagram.com/hungarocontrol/" target="_blank">
                    <img src={withPrefix("/svg/instagram_icon_white.svg")} alt="instagram" title="instagram" className={`${cm.logoSocial}`} />
                  </a>
                  <a href="https://twitter.com/HungaroControl" target="_blank">
                    <img src={withPrefix("/svg/twitter_icon_white.svg")} alt="twitter" title="twitter" className={`${cm.logoSocial}`} />
                  </a>
                </span>
              </div>
          </div>
      </div>
      <div className={`${cm.sharing}`}>
          <div className={`${cm.toggle}`}></div>
          <div className={`${cm.content}`}>
              <a href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${shareHref};title=HungaroControl&amp;summary=&amp;source=`} target="_blank"><img src={withPrefix("/svg/sharing_linkedin.svg")} alt="Megosztás linkedIn-en" title="Megosztás LinkedIn-en" width="34" height="34"/></a>
              <a href={`https://twitter.com/home?status=${shareHref}`} target="_blank"><img src={withPrefix("/svg/sharing_twitter.svg")} alt="Megosztás twitter-en" title="Megosztás Twitter-en" width="34" height="34"/></a>
              <a href={`https://www.facebook.com/sharer/sharer.php?u=${shareHref}`} target="_blank"><img src={withPrefix("/svg/sharing_facebook.svg")} alt="Megosztás Facebook-on" title="Megosztás facebook-on" width="34" height="34"/></a>
              <a href={`mailto:?subject=HungaroControl&amp;body=${shareHref}`} target="_blank"><img src={withPrefix("/svg/sharing_mail.svg")} alt="Megosztás e-mailen" title="Küldés e-mailben" width="34" height="34"/></a>
          </div>
      </div>
      <div className={`${cm.textFooter}`}>
          <div className="row">
              <div className="large-6 columns hidden-large-down">{fm({ id: "footer.copyright" })}</div>
              <div className="large-6 columns text-medium-right">
                  <a href="/kapcsolat/linkek">{fm({ id: "footer.linkek" })}</a>
                  <a href="/impresszum">{fm({ id: "footer.impresszum" })}</a>
                  <a href="/adatvedelem">{fm({ id: "footer.adatvedelem" })}</a>
                  <a href="/files/etikai-kodex.pdf" target="_blank">{fm({ id: "footer.etikaikodex" })}</a>
                  <a href="/files/akadalymentesites/Akadalymentesitesi_nyilatkozat_hungarocontrol_hu.pdf">{fm({ id: "footer.akadalymentesitesi" })}</a>
              </div>
          </div>
      </div>
    </footer>
  )
}

export default Footer
