/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { getLang } from "../utils/i18n"

interface Props {
    description?: string,
    lang?: string,
    meta?: Array<React.DetailedHTMLProps<React.MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>>,
    title?: string,
    ogTitle?: string | null,
    ogImage?: string | null,
    ogDescription?: string | null
}

const SEO: React.FC<Props> = ({ description, lang, meta, title, ogDescription, ogImage, ogTitle }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={lang ? {
        lang,
      } : undefined}
      title={site.siteMetadata.title}
      meta={([
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: ogTitle ?? title,
        },
        {
          property: `og:description`,
          content: ogDescription ?? metaDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: ogTitle ?? title,
        },
        {
          name: `twitter:description`,
          content: ogDescription ?? metaDescription,
        },
      ] as Array<React.DetailedHTMLProps<React.MetaHTMLAttributes<HTMLMetaElement>, HTMLMetaElement>>).concat(meta || [])}
    >
        <script data-culture={getLang().toUpperCase()} id="Cookiebot" src="https://consent.cookiebot.com/uc.js" data-cbid="4cde6344-311e-4314-ab92-854e97ad4e65" data-blockingmode="auto" type="text/javascript"></script>
        <style dangerouslySetInnerHTML={{__html: " #CybotCookiebotDialogNav #CybotCookiebotDialogNavAbout { display: none !important; }"}} />
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'}
);var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-K6XK5CK');`}</script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

// SEO.propTypes = {
//   description: string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// }

export default SEO
