//import { Link } from "gatsby"
//import PropTypes from "prop-types"
import React, { useContext } from "react"
import Mainmenu from "./Mainmenu"

import cm from "./Header.module.scss"
import { Link, withPrefix } from "gatsby"
import { getRoute, getMainSiteBaseUrl } from "../utils/i18n"
import { useToggle } from "../utils/hooks"
import { useState } from "react"
import { useIntl } from "react-intl"
import { toggleLowVisionMode } from "../utils/misc"
import { AppContext } from "../utils/appContext"

interface Props {
  siteTitle: string | null | undefined
}

const Header: React.FC<Props> = ({ siteTitle }) => {
  const { formatMessage: fm } = useIntl()
  const { mainSiteUrl } = useContext(AppContext)
  const [isMenuOpened, toggleMenu] = useToggle(false)
  const [isSearchBoxActive, toggleSearch] = useToggle(false)
  const [searchValue, setSearchValue] = useState("")
  return (
    <header className={cm.topHeader}>
      <div className={cm.subHead}>
        <div className="row">
          <div className="column all-12 text-right">
            <ul>
              <li className="hidden-large-down">
                <a
                  href="#"
                  className={cm.highcontrast}
                  onClick={toggleLowVisionMode}
                >
                  {fm({ id: "menu.gyengenlato" })}
                </a>
              </li>
              <li className="hidden-large-down">
                <a href="https://hungarocontrol.hu/">HU</a>
              </li>
              <li className="hidden-large-down">
                <a href="https://en.hungarocontrol.hu/">EN</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="column all-12">
          <a
            className={`${cm.mobilemenuButton} hidden-large-up`}
            rel="topmenu"
            onClick={toggleMenu}
          >
            <span></span>
          </a>
          <a href={mainSiteUrl} className={cm.siteLogo}>
            <img
              src={withPrefix("/svg/hungaro-control_logo.svg")}
              alt="Hungaro Control"
            />
            <img
              src={withPrefix("/svg/hungaro-control_logo-white.svg")}
              alt="Hungaro Control"
            />
          </a>
          <div className={cm.menu}>
            <Mainmenu opened={isMenuOpened} onClose={toggleMenu} />
          </div>
          <a onClick={toggleSearch}>
            <div
              className={cm.searchToggle}
              title={fm({ id: "search.title" })}
            ></div>
          </a>
        </div>
      </div>
      <div
        className={`${cm.headerSearch} ${isSearchBoxActive ? cm.active : ""}`}
      >
        <form method="get" action={withPrefix(`/${getRoute("search")}`)}>
          <input
            type="search"
            value={searchValue}
            onChange={({ currentTarget: { value } }) => setSearchValue(value)}
            name="keyword"
            placeholder={fm({ id: "search.inputplaceholder" })}
            autoComplete="off"
            required
            pattern=".{3,}"
            title={fm({ id: "search.inputtooltip" })}
          />
          <button type="submit">{fm({ id: "search.searchbtn" })}</button>
        </form>
      </div>
    </header>
  )
}

export default Header
