const langFile = require(`../i18n/${getLang()}.json`);

export function getLang() {
    return process.env.GATSBY_LOCALE || "hu";
}

export function getKey(key: string): string {
    const translation = langFile[key];
    if (typeof translation === "undefined") {
        console.warn(`Translation key "${key}" not found in locale "${getLang()}"`);
        return key;
    }
    return translation;
}

export type RouteKey = "search" | "index" | "archive" | "article" | "authors" | "author";
export function getRoute(route: RouteKey): string {
    return getKey(`routes.${route}`);
}

export function getMainSiteBaseUrl() {
    const envKey = `GATSBY_HOMEPAGE_URL_${getLang().toUpperCase()}`
    const value = process.env[envKey] ?? "https://www.hungarocontrol.hu"
    return value;
}
