export async function loadAndAwaitScript(url: string): Promise<void> {
    var scr = document.createElement('script');
    // const head = document.head || document.getElementsByTagName('head')[0];
    scr.src = url;
    
    document.body.appendChild(scr)
    const loadPromise = new Promise(resolve => scr.addEventListener("load", resolve));

    // head.insertBefore(scr, head.firstChild);
    
    await loadPromise;
}

const LOW_VISION_CLASSNAME = "highcontrast";

export function toggleLowVisionMode(): void {
    document.body.classList.toggle(LOW_VISION_CLASSNAME);
}

export function basicStripHtmlTags(input: string): string {
    return input.replace(/<[A-z/].*?>/gi, "");
}
