//import { Link } from "gatsby"
import React, { useContext } from "react"
import { useIntl } from "react-intl"

import "../styles/common.scss"
import cm from "./Mainmenu.module.scss"
import { AppContext, MenuItem } from "../utils/appContext"
import { toggleLowVisionMode } from "../utils/misc"

const MenuItemComponent: React.FC<{
  url: string
  title: string
  children: MenuItem[]
}> = ({ url, title, children }) => {
  const childrenRef = React.useRef<HTMLUListElement | null>(null)
  const [opened, setOpened] = React.useState(false)
  const { mainSiteUrl } = useContext(AppContext)
  const hasChildren = !!children && children.length > 0

  const toggleMenu = (): void => {
    setOpened(!opened)
  }

  return (
    <li className={hasChildren ? cm.hasChild : ""}>
      <a href={getUrlForMenuItem(url, mainSiteUrl)}>{title}</a>
      {hasChildren && (
        <>
          <span onClick={toggleMenu} className={opened ? cm.open : ""} />
          <ul
            ref={childrenRef}
            style={{
              maxHeight: `${opened ? childrenRef.current?.scrollHeight || 0 : 0}px`,
              opacity: opened ? 1 : 0,
            }}
          >
            {children.map(child => {
              return (
                <MenuItemComponent
                  key={child.menu_item_id}
                  url={child.menu_item_url}
                  title={child.menu_item_title}
                  children={child.children || []}
                />
              )
            })}
          </ul>
        </>
      )}
    </li>
  )
}

const Mainmenu: React.FC<{ opened: boolean; onClose: () => void }> = ({
  opened,
  onClose,
}) => {
  const { formatMessage: fm } = useIntl()
  const { mainMenu, mainSiteUrl } = useContext(AppContext)

  return (
    <ul className={`${cm.mainmenu} ${opened ? cm.active : ""}`}>
      <a href="#" className={`${cm.close} hidden-large-up`} onClick={onClose}>
        <span />
      </a>
      {mainMenu.data.map(item => {
        return (
          <MenuItemComponent
            key={item.menu_id}
            title={item.menu_item_title}
            url={item.menu_item_url}
            children={item.children || []}
          />
        )
      })}
      <li className="hidden-large-up">
        <a href="#" className={cm.highcontrast} onClick={toggleLowVisionMode}>
          {fm({ id: "menu.gyengenlato" })}
        </a>
        <span>
          <span>HU</span>
          <span> &nbsp;&nbsp;|&nbsp;&nbsp;</span>
          <a href="https://en.hungarocontrol.hu/">EN</a>
        </span>
      </li>
    </ul>
  )
}

const getUrlForMenuItem = (url: string, baseUrl: string) => {
  if (/^https?:\/\//i.test(url)) {
    return url
  }
  return `${baseUrl}${url}`
}

export default Mainmenu
