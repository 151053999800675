import { useEffect, useReducer, useState, useCallback } from "react";

interface UseAsync<T> {
    result: T | null;
    loading: boolean;
    error: Error | null;
}

interface UseAsyncOptions<T, F extends any[]> {
    asyncFunc(...params: F): Promise<T>;
    timeout?: number;
    skip?: boolean;
}

type UseAsyncReducerAction<T> =
    | { type: "SUCCESS", payload: T }
    | { type: "ERROR", payload: Error }
    | { type: "RESET" }
    | { type: "START_LOADING" };

type UseAsyncState<T> =
    | {
        result: T | null;
        loading: boolean;
        error: null;
    }
    | {
        result: null;
        loading: boolean;
        error: Error;
    };
        
export function useAsync<T, F extends any[]>(options: UseAsyncOptions<T, F>, ...funcParams: F): UseAsync<T> {
    const { asyncFunc, timeout, skip } = options;

    const reducer: React.Reducer<UseAsyncState<T>, UseAsyncReducerAction<T>> = (prevState, action) => {
        switch (action.type) {
            case "SUCCESS":
                return { result: action.payload, error: null, loading: false };
            case "ERROR":
                return { result: null, error: action.payload, loading: false };
            case "RESET":
                return { result: null, error: null, loading: false };
            case "START_LOADING":
                return { ...prevState, loading: true };
        }
    }

    const [{ result, error, loading }, dispatch] = useReducer(reducer, { result: null, error: null, loading: false } )

    useEffect(() => {
        let completed = false;
        if (!skip) {
            dispatch({ type: "START_LOADING" });
            asyncFunc(...funcParams)
                .then(result => {
                    if (!completed) {
                        completed = true;
                        dispatch({ type: "SUCCESS", payload: result });
                    }
                })
                .catch(e => {
                    if (!completed) {
                        completed = true;
                        dispatch({ type: "ERROR", payload: e });
                    }
                });
            if (timeout) {
                setTimeout(() => {
                    if (!completed) {
                        completed = true;
                        dispatch({ type: "ERROR", payload: new Error("Timed out") });
                    }
                }, timeout);
            }
        } else {
            dispatch({ type: "RESET" });
        }
        
        return () => {
            completed = true;
        }
    }, [skip, ...funcParams]);
    
    return {
        result,
        loading,
        error
    }
}

const fetchJson = async (input: RequestInfo, init?: RequestInit | undefined) => {
    const result = await fetch(input, init);
    return await result.json();
}

interface UseFetchJson<T> {
    data: T | null;
    loading: boolean;
    error: Error | null;
}

export function useFetchJson<T = any>(input: RequestInfo | null, init?: RequestInit | undefined, timeout?: number): UseFetchJson<T> {
    const { result, error, loading } = useAsync({ asyncFunc: fetchJson, timeout, skip: !input }, input || "", init);
    return {
        data: result,
        error,
        loading
    };
}

interface RestWPPost {
    id: number;
    date: string;
    slug: string;
    excerpt: {
        rendered: string;
    };
    title: {
        rendered: string;
    },
    featured_media: number;
    media_url: string | undefined;
}

interface WpPost {
    id: number;
    date: string;
    slug: string;
    excerpt: string;
    title: string,
    featured_media: {
        source_url?: string;
    };
}

interface WpSearchResult {
    searchText: string;
    results: WpPost[];
}

const wpApiUrl = `${process.env.GATSBY_WP_API_PROTOCOL}://${process.env.GATSBY_WP_API_HOSTNAME}/wp-json/wp/v2`;

async function fetchMediaForPost(post: RestWPPost): Promise<RestWPPost> {
    if (!post.featured_media) {
        return post;
    }
    try {
        const media = await fetchJson(`${wpApiUrl}/media/${post.featured_media}`);
        const mediaUrl = media?.media_details?.sizes?.medium_large?.source_url;
        return { ...post, media_url: mediaUrl };
    } catch (e) { }
    
    return post;
}

function convertRestWpPost(post: RestWPPost): WpPost {
    return {
        ...post,
        excerpt: post.excerpt.rendered,
        title: post.title.rendered,
        featured_media: {
            source_url: post.media_url
        }
    }
}

async function fetchWpSearch(searchText: string): Promise<WpSearchResult> {
    const posts = await fetchJson(`${wpApiUrl}/posts?search=${encodeURIComponent(searchText)}`) as Array<RestWPPost>;
    const postsWithMedia = await Promise.all(posts.map(fetchMediaForPost));
    return {
        searchText,
        results: postsWithMedia.map(convertRestWpPost)
    };
}

export function useWpSearch(searchText: string | null, timeout?: number) {
    const { result, error, loading } = useAsync({ asyncFunc: fetchWpSearch, skip: !searchText, timeout }, searchText);
    return { 
        result,
        error,
        loading
    };
}

export function useToggle(defaultValue = false): [boolean, () => void] {
    const [toggledValue, setToggledValue] = useState(defaultValue);
    const toggle = useCallback(() => {
        setToggledValue(!toggledValue);
    }, [toggledValue]);
    return [toggledValue, toggle];
}
