import React from "react";
import Gql from "../typings/gql";
import fetch from "node-fetch";
import { getLang, getMainSiteBaseUrl } from "./i18n";

export interface MenuItem {
    menu_item_id: number;
    menu_item_title: string;
    menu_item_url: string;
    menu_id: number;
    parent_id?: any;
    weight: number;
    is_open: number;
    is_blank: number;
    is_active: number;
    created: string;
    last_modified: string;
    is_deleted: number;
    children?: MenuItem[];
}

export interface MenuRoot {
    command?: any;
    success?: boolean;
    errors?: any;
    element?: any;
    message?: any;
    data: MenuItem[];
    microtime?: number;
}

export const fetchMainMenu = async (): Promise<MenuRoot> => {
    // process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";
    const slug = { en: "main_en", hu: "main" }[getLang() as "hu" | "en"];
    const result = await fetch(`${process.env.GATSBY_MENU_API_BASE_URL}/ajax/MenuModule/getMenu?slug=${slug}`, { headers: { "Authorization": "Basic YmZhZG1pbjpuYWd5aGFsMTIz" } });
    const json = await result.json();
    return json;
}

export interface AppContext {
    categories: Pick<Gql.Wordpress__Category, "acf">[];
    mainMenu: MenuRoot;
    mainSiteUrl: string;
}

export const AppContext = React.createContext<AppContext>({ categories: [], mainMenu: { data: [] }, mainSiteUrl: getMainSiteBaseUrl() });
