/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import Header from "./Header"
import Footer from "./Footer";

import "../styles/common.scss"
import { AppContext } from "../utils/appContext";

type Props = AppContext;

const Layout: React.FC<Props> = ({ children, categories, mainMenu, mainSiteUrl }) => {
  return (
    <>
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K6XK5CK" height="0" width="0" style={{ display: "none", visibility: "hidden" }}></iframe>
      </noscript>
      <div className="wrapper">
        <AppContext.Provider value={{ categories, mainMenu, mainSiteUrl }}>
          <Header siteTitle="HC-Blog" />
          <article className="main-content">
            {children}
          </article>
          <Footer />
        </AppContext.Provider>
      </div>
    </>
  )
}

export default Layout
